import React, { Component ,Fragment } from "react";
import { GiHamburgerMenu, GiPlatform } from "react-icons/gi";
import { FiCheck} from "react-icons/fi";



class Plataforma extends Component{
    render(){
        return(
            <Fragment>

  {/* Start About Area */}
  <div className="about-area ptb--90 bg_color--5" id="platform">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                <div data-aos="fade-up" className="thumb position-relative m-auto" style={{maxWidth: "500px"}}>
                                        <img style={{boxShadow: "#32074061 8px 6px 15px 3px"}} className="w-100" src={!this.props.img ? "/assets/images/dojopy/plataforma_bootcamp.webp": this.props.img} alt="plataforma_bootcamp"/>
                                        {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen0} videoId='-_IVPd1ryvI' onClose={() => this.setState({isOpen0: false})} /> */}
                                        {/* <button className="video-popup position-top-center" onClick={this.openModal0}><span className="play-icon"></span></button> */}
                                    </div>
                                </div>

                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                       
                                            <h2 className="title mb-0">  {!this.props.langUS ? "PLATAFORMA": "PLATFORM"} </h2>
                                            <div style={{width: window.type_device ? "200px": "375px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                                            {!this.props.langUS ?
                                            <>
                <p>
                Todos los miembros cuentan con acceso a la plataforma donde encuentran los módulos del Bootcamp Full Stack.
                <b>
                Impulsada por Inteligencia Artificial, la plataforma ofrece lecciones y retroalimentación personalizadas según tu progreso.
                </b>
                </p>
                </>:
                <>
                <p>
                 All students and instructors have access to a platform where they find the materials,
                  community, calendar and the links of the sessions by Zoom,
                  In addition, the classes are recorded on the platform so that you can see them as many times as you want.
                 with unlimited access. <br/>
                 <b>
                 The platform is powered by Artificial Intelligence, which means you'll receive personalized lessons and feedback based on your progress.
                 </b>
                 </p>
                </>}
                                       
                                        </div>
                                    <div className="row mt--30">
                                        
                                   

                                            <div className="col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>
                                                
                                                {!this.props.langUS ?  "Módulos Full Stack con actividades y revisiones.": "Full Stack modules with activities and reviews."}
                                                </h4>
                                                </div>
                                            </div>



                                            <div className="col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>  {!this.props.langUS ? "Proyectos y soluciones compartidas en la comunidad.": "Access projects and shared solutions from the community."}
                                                </h4>
                                                </div>
                                            </div>
                                  
                                            <div className="col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>  {!this.props.langUS ? "Red social exclusiva para fomentar la interacción.": "Community (we created our own social network)."}
                                                </h4>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="about-us-list">
                                                <h4 className="title"><FiCheck style={{"color": "slateblue"}}/>  {!this.props.langUS ? "Acceso completo de por vida.": "Lifetime full access."}
                                                </h4>
                                                </div>
                                            </div>

                                        
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}


            </Fragment>
        )
    }
}




export default Plataforma;