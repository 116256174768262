import React, { useState } from 'react';
import {FaTwitter ,FaInstagram ,FaFacebookF, FaWhatsapp, FaYoutube, FaTiktok} from "react-icons/fa";
import { GoRocket } from "react-icons/go";
import {FiMail , FiPhoneForwarded} from "react-icons/fi";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/DojoFullStack', 'red': 'face'},
    {Social: <FaTiktok /> , link: 'https://www.tiktok.com/@dojopy', 'red': 'tiktok'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/dojofullstack', 'red': 'insta'},
    // {Social: <FaTwitter /> , link: 'https://twitter.com/DojoFullStack', 'red': 'twit'},
]

const FooterTwo = ({langUS=false}) => {

    const [Email, setEmail] = useState("");

    function onSubmitEmail(e) {
        e.preventDefault();

        if(Email === "" ){
            return;
        }

        axios.post('https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev',
        { lead: true, evento: false, bootcamp: 'Web', email: Email},)
        .then(res => {
        toast.success(!langUS ? 'Gracias!, pronto tendrás noticias!': 'Thank you!, soon you will have news!');
        })

    }


    return (

        <div>
             {/* Start Contact Top Area  */}
 <div className="rn-contact-top-area pt--100 pb--70 bg_color--5">
                    <div className="container">

                        <div className="row">
                            <div className="col-12 col-md-4">
                            <div className="rn-address" style={{border: "2px solid #ebebeb"}}>
                                <div className="icon" style={{top: "-29px"}}>
                                    <GoRocket style={{fontSize: "45px"}} />
                                </div>
                                <div className="inner">
                                    <h4 className="title">DojoFullStack</h4>
                                    {!langUS ?
                                    <>
                                    <p>
                                    🚀 Startup educativa de Desarrollo Full Stack Web
                                    </p>
                                    <p>
                                    🌏 Desde cualquier parte del mundo.
                                    </p>
                                    <p>
                                    💻 100% online en vivo.
                                    </p>
                                    </>:
                                    <>
                                    <p>
                                     🚀 Educational Startup of Full Stack Web Development.
                                     </p>
                                     <p>
                                     🌏 From anywhere in the world.
                                     </p>
                                     <p>
                                     💻 100% online live
                                     </p>
                                    </>    
                                }
                                   
                                </div>
                            </div>
                        </div>

                          

                            {/* Start Single Address  */}
                            <div className="col-12 col-md-4">
                            <div className="rn-address" style={{border: "2px solid #ebebeb"}}>
                                <div className="icon" style={{top: "-29px"}}>
                                        <FiMail style={{fontSize: "45px"}} />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        
                                        {langUS ? <>
                                            <p><a target="_blank" href="mailto:inscripciones@dojofullstack.com?subject=Informes Bootcamp">enroll@dojofullstack.com</a></p>
                                            <p><a target="_blank" href="mailto:hola@dojofullstack.com?subject=Informes Bootcamp">hello@dojofullstack.com</a></p>
                                        </>: <>
                                        <p><a target="_blank" href="mailto:inscripciones@dojofullstack.com?subject=Informes Bootcamp">inscripciones@dojofullstack.com</a></p>
                                        <p><a target="_blank" href="mailto:hola@dojofullstack.com?subject=Informes Bootcamp">hola@dojofullstack.com</a></p>
                                        </>}
                                      
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-12 col-md-4">
                            <div className="rn-address" style={{border: "2px solid #ebebeb"}}>
                                <div className="icon" style={{top: "-29px"}}>
                                        <FaWhatsapp style={{fontSize: "45px"}} />

                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Whatsapp</h4>
                                        <p><a target="_blank" href="https://api.whatsapp.com/send?phone=51936670597&text=Hola, información sobre el Bootcamp Full Stack online en vivo.">
                                            +51936670597
                                        </a></p>
                                </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
           

                <div className="col-12">
                       <div className="inner">
                        
                       <div className="inner">
                    <div className="contact-form--1">
                        <div className="container" style={{maxWidth: "550px"}}>
                            <div className="row row--35 align-items-start">
                                <div className="col-12">



                                <div style={{textAlign: "-webkit-center", marginTop: "15px"}} className="form-wrapper">
                           
                            
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                        

                       </div>
                   </div>

           <div className="wrapper plr--50 plr_sm--20" style={{paddingBottom: "50px"}}>
                <div className="row text-white" style={{margin: "15px"}}>
                   <div className="col-md-3 col-12">
                        <a href="/">Bootcamp Full Stack</a>
                    </div>
                    {/* <div className="col-md-3 col-12">
                        <a href="/bootcamp-frontend">Bootcamp Frontend ReactJS</a>
                    </div> */}

                    {/* <div className="col-md-3 col-12">
                        <a href="/bootcamp-backend">BootCamp Backend Python</a>
                    </div> */}
{/* 
                    <div className="col-md-3 col-12">
                        <a href="/bootcamp-aws">Bootcamp Cloud AWS</a>
                    </div> */}
                
          
                    <div className="col-md-3 col-12">
                           <a href="/#preguntas-frecuentes">
                           
                           {langUS ? "Frequently Asked Questions": "Preguntas Frecuentes"}
                           </a>
                    </div>
                
                    <div className="col-md-3 col-12">
                        {langUS ?
                              <a href="/privacy-policies">
                                Privacy policies
                              </a>:
                                <a href="/politicas-privacidad">
                                Políticas de Privacidad
                                </a>                    
                        }
                         
                    </div>
                    <div className="col-md-3 col-12">

                         {langUS ?
                              <a href="/refund-policies">
                                Refund Policies
                              </a>:
                                <a href="/politicas-reembolso">
                                Políticas de Reembolso
                                </a>                    
                        }

                    
                    </div>


               </div>
               
               <div className="row align-items-center justify-content-between">

             

                   <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                       <div className="inner">
                           <div className="logo text-center text-sm-left mb_sm--20">
                               <a href="https://dojofullstack.com">
                                   <img height="65px" src="/assets/images/logo/dojopy_software.webp" alt="DojoFullStack"/>
                               </a>
                           </div>
                       </div>
                   </div>
                   <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-5">
                       <div className="inner text-center">
                           <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                           {/* <li key="4"><a style={{color: "#00acee"}} target="blank" href={"https://twitter.com/DojoFullStack"}> <FaTwitter style={{fontSize: "30px"}}/>  </a></li> */}
                           <li key="1"><a style={{color: "#3b5998"}} target="blank" href={"https://www.facebook.com/DojoFullStack"}> <FaFacebookF style={{fontSize: "30px"}}/> </a></li>
                            <li key="3"><a style={{color: "#E1306C"}} target="blank" href={"https://www.instagram.com/dojofullstack"}> <FaInstagram style={{fontSize: "30px"}}/> </a></li>
                            <li key="2"><a style={{color: "#FE2C55"}} target="blank" href={"https://www.tiktok.com/@dojofullstack"}> <FaTiktok style={{fontSize: "30px"}}/> </a></li>
                           </ul>
                       </div>
                   </div>
                   <div className="col-lg-4 col-md-12 col-sm-12 col-12 mb-5">
                       <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                           <div className="text">
                               <p>Copyright © 2025 DojoFullStack -  {!langUS ? "Todos los derechos reservados.": "All rights reserved."} </p>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <ToastContainer
                position="bottom-center"
                autoClose={5000}
                zIndex={10000}
                />
        </div>
        
       
    )
}
export default FooterTwo;
