import React, { Component } from "react";
import PageHelmet from "../../component/common/Helmet";
import axios from "axios";
import Selectrix from "react-selectrix";
import Modal from "react-bootstrap/Modal";

import "react-toastify/dist/ReactToastify.css";
import ReactCountryFlag from "react-country-flag";
import FlipCountdown from "@rumess/react-flip-countdown";
import { GoRocket } from "react-icons/go";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import TestimonialOne from "../../blocks/testimonial/TestimonialOne";
import Typical from 'react-typical';
import { FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle, FiPackage} from "react-icons/fi";
import { FaRegCalendarAlt, FaWhatsapp } from "react-icons/fa";
import { IoMdTime } from "react-icons/io";
import BrandTwo from "../../elements/BrandTwo";
import cx from "classnames";
import NonPassiveTouchTarget from "../NonPassiveTouchTarget";
import TouchCarousel, { clamp } from "react-touch-carousel";
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";
import data2 from "../data2";
import { AiOutlineDown, AiOutlineRight, AiOutlineClose } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import FooterTwoFunnel from "../../component/footer/FooterTwoFunnel";
import { TiTicket } from "react-icons/ti";

const KeyCountry =  [
  {key: "PE", label: "Perú", countryNumber: "+51"},
  {key: "EC", label: "Ecuador", countryNumber: "+593"},
  {key: "GT", label: "Guatemala", countryNumber: "+502"},
  {key: "CR", label: "Costa Rica", countryNumber: "+506"},
  {key: "MX", label: "México", countryNumber: "+52"},
  {key: "SV", label: "El Salvador", countryNumber: "+503"},
  {key: "HN", label: "Honduras", countryNumber: "+504"},
  {key: "UY", label: "Uruguay", countryNumber: "+598"},
  {key: "PY", label: "Paraguay", countryNumber: "+595"},
  {key: "PA", label: "Panamá", countryNumber: "+507"},
  {key: "NI", label: "Nicaragua", countryNumber: "+505"},
  {key: "US", label: "Estados Unidos", countryNumber: "+1"},
]

const Container2 = touchWithMouseHOC(CarouselContainer2);

const cardSize = 300;
const cardSize2 = 280;
const cardPadCount = 2;
const carouselWidth = clamp(window.innerWidth, 0, 960);


function CarouselContainer2(props) {
  const {
    cursor,
    carouselState: { active, dragging },
    ...rest
  } = props;
  let current = -Math.round(cursor) % data2.length;
  while (current < 0) {
    current += data2.length;
  }
  // Put current card at center
  const translateX =
    (cursor - cardPadCount) * 300 + (carouselWidth - 300) / 2;
  return (
    <NonPassiveTouchTarget
      className={cx("carousel-container", {
        "is-active": active,
        "is-dragging": dragging,
      })}
      style={{height: "450px"}}
    >
      <NonPassiveTouchTarget
        className="carousel-track"
        style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
        {...rest}
      />

      <div className="carousel-pagination-wrapper">
        <ol className="carousel-pagination">
          {data2.map((_, index) => (
            <li key={index} className={current === index ? "current" : ""} />
          ))}
        </ol>
      </div>
    </NonPassiveTouchTarget>
  );
}

class FunnelStart extends Component {
  constructor() {
    super();

    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    this.state = {
      linkPayUSD: "",
      pricingAPI: {},
      slugBootcamp: "fullstack",
      bootcampSlugMensual: "fullstack",
      Temario: "",
      Programa: "",
      isOpen: false,
      countryCode: "US",
      Email: "",
      Phone: "",
      Name: "",
      isOpenTemario: false,
      isPdfPrice: false,
      langUS: false,
      isPlaying: false,
      isOpenPlanPay: false,
      price_mensual_regular: "",
      price_completo_regular: "",
      price_completo: "",
      price_mensual: "",
      titlePricePre: "",
      demoTitle: "",
      demoUrl: "",
      demoPreview: "",
      fechaLimite:  '',
      dia:  '',
      DescuentoBody: "",
      visitasNumber: this.getRandomVisitasNumber(),
      videoRef: React.createRef(),
      isOpenLead: false,
      loadingEnroll: false,
      demo_alt: false,
      body_intro: false,
      select_mes: "",
      DataFechas: [],
      select_turno: "",
      isOpenResume: false,
      Turnos: [
        {key: "noche", label: "Noche"},
        {key: "tarde", label: "Tarde"},
    ],
      durationVideo: 0,
      durationPercen: 0,
      registerRequerid: false,
      videoInit: false,
      selectFecha: false,
      isModalWS: false,
      optionPlanPago: "0",
      showMore: false,
      showWeb: false,
      KeysPlanes: [
        {"label": "Entrada Gratuita (1 Sesión)", "key": "0"},
        {"label": "💎 Golden Pass VIP (Acceso Premium)", "key": "1"}
      ]
    };
    this.GetLinkWS = this.GetLinkWS.bind(this);
    this.GetLinkPay = this.GetLinkPay.bind(this);
    
    this.openApp = this.openApp.bind(this);
    this.openModalEmail = this.openModalEmail.bind(this);
    this.onRenderOption = this.onRenderOption.bind(this);
    this.DownloadPdf = this.DownloadPdf.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.DynamicCountry = this.DynamicCountry.bind(this);
    
  }


  onRenderSelectionPais( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: 5 }}>
{ selected ?
        <span style={{"color": "white"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2.3em',
                height: '2.3em',
                padding: '7px'
            }}
            title="país"
    />  {selected.countryNumber}
        </span>
        :
           <span style={{padding: 5}}>País</span>
    }
    
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}



  onRenderOptionPlan ( option, index ) {
    return (<li key={index} style={{padding: "10px", fontSize: "18px", fontWeight: "bold"}}>
            { option.label }
            </li>)
  }



onRenderSelectionPlan( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: "10px" }}>
{ selected ?
        <span style={{"color": "white", fontSize: "18px", fontWeight: "bold"}}>
           {selected.label}
        </span>
        :
           <span style={{padding: "10px"}}></span>
    }
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}


  onRenderSelection( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: 5 }}>
{ selected ?
        <span style={{"color": "#1f1f25"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2.3em',
                height: '2.3em',
                padding: '7px'
            }}
            title="país"
    />  {selected.countryNumber}
        </span>
        :
           <span style={{padding: 5}}>País</span>
    }
    
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}

  showMatrix(){
    // geting canvas by Boujjou Achraf
    var c = document.getElementById("dojo-mural");
    var ctx = c.getContext("2d");

    //making the canvas full screen
    c.height = window.innerHeight;
    c.width = window.innerWidth;

    //chinese characters - taken from the unicode charset
    var matrix = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}";
    //converting the string into an array of single characters
    matrix = matrix.split("");

    var font_size = 10;
    var columns = c.width/font_size; //number of columns for the rain
    //an array of drops - one per column
    var drops = [];
    //x below is the x coordinate
    //1 = y co-ordinate of the drop(same for every drop initially)
    for(var x = 0; x < columns; x++)
        drops[x] = 1; 

    //drawing the characters
    function draw()
    {
        //Black BG for the canvas
        //translucent BG to show trail
        ctx.fillStyle = "rgba(0, 0, 0, 0.01)";
        ctx.fillRect(0, 0, c.width, c.height);

        ctx.fillStyle = "#25D366";//green text
        ctx.font = font_size + "px arial";
        //looping over drops
        for(var i = 0; i < drops.length; i++)
        {
            //a random chinese character to print
            var text = matrix[Math.floor(Math.random()*matrix.length)];
            //x = i*font_size, y = value of drops[i]*font_size
            ctx.fillText(text, i*font_size, drops[i]*font_size);

            //sending the drop back to the top randomly after it has crossed the screen
            //adding a randomness to the reset to make the drops scattered on the Y axis
            if(drops[i]*font_size > c.height && Math.random() > 0.975)
                drops[i] = 0;

            //incrementing Y coordinate
            drops[i]++;
        }
    }

    setInterval(draw, 30);

  }

    onRenderOption(option, complete) {
      return (
        <li>
          <ReactCountryFlag
            countryCode={option.key}
            svg
            style={{
              width: "2em",
              height: "2em",
              padding: "1px",
            }}
            title="país"
          />{" "}
          {complete ? option.countryNumber : option.key}
        </li>
      );
    }
  
  
  


  onSubmit(e) {
    // e.preventDefault();
    // if (this.state.Email.trim() === "") {
    //   toast.warning("El campo Email es requerido.");
    //   return;
    // }

    if (this.state.Email.trim() === "" || !this.state.Email.trim().includes("@") ) {
      toast.dark('¡Ups! El campo Email es necesario para continuar.');
      return;
    }


    this.setState({ loadingEnroll: true, sendDataForm: true });

    localStorage.setItem("email", this.state.Email || "");
    // localStorage.setItem('name', this.state.Name);
    // localStorage.setItem('phone', this.state.Phone);

    if (this.state.Phone){
      localStorage.setItem('phone', this.state.Phone);
    }


    window.fbq('track', 'Contact', {
      content_category: 'Evento bootcamp',
      user_data: {
        em: this.state.Email,
      }
    });



    setTimeout(() => {
      const link = this.GetLinkWS();
      this.setState({loadingEnroll: false, isModalWS: true });
      window.open(link, '_blank').focus();
    }, 2000);
    

    const source = window.location.search.split('source=')[1] || '';


    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: "Web full stack",
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        phone: this.state.Phone,
        Token: "c3b4b89c",
        landing: 'funnel-bootcamp',
        ticket: "" ,
        source: source,
      })
      .then((res) => {
        // window.location.href = "/intro-fullstack";
        // const link = this.GetLinkWS();
        // this.setState({loadingEnroll: false });
        // window.open(link, '_blank').focus();
      });
  }

  getRandomVisitasNumber() {
    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    return rango[Math.floor(Math.random() * rango.length)];
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  DynamicCountry(country = "") {
    console.log("this.state.slugBootcamp", this.state.slugBootcamp);
    console.log(this.state.pricingAPI[this.state.slugBootcamp].fechaLimite);
    
    
    if (country) {
      this.setState({ countryCode: country });
    }


    this.setState({
      fechaLimite: this.state.pricingAPI[this.state.slugBootcamp].fechaLimite || '',
      linkPayUSD: this.state.pricingAPI[this.state.slugBootcamp].linkPayUSD || '',
      isPricePais: false,
      utc_offset: "-0500",
      utc_dif: 0,
      price_view_base:
        this.state.pricingAPI[this.state.slugBootcamp]["price"]["usd"][
          "price_view_base"
        ],
      titlePrice: this.state.pricingAPI[this.state.slugBootcamp]["titlePrice"],
      price_completo:
        this.state.pricingAPI[this.state.slugBootcamp]["price"]["usd"][
          "price_view_base"
        ], //495
      price_completo_regular:
        this.state.pricingAPI[this.state.slugBootcamp]["price"]["usd"][
          "price_view_base_regular"
        ],
        DataFechas: this.state.pricingAPI[this.state.slugBootcamp]['fechas'],
        select_mes:  this.state.pricingAPI[this.state.slugBootcamp]['fechas'][0]['mes'],
        select_turno: this.state.pricingAPI[this.state.slugBootcamp]['fechas'][0]['turno'],
    });

    try {
      if (this.state.pricingAPI[this.state.slugBootcamp].price[country]) {
        this.setState({
          isPricePais: true,
          price_completo:
            this.state.pricingAPI[this.state.slugBootcamp]["price"][country][
              "price_view_base"
            ], //495
          price_completo_regular:
            this.state.pricingAPI[this.state.slugBootcamp]["price"][country][
              "price_view_base_regular"
            ],
          price_mensual:
            this.state.pricingAPI[this.state.slugBootcamp]["price"][
              country
            ]["price_view_final"],
          price_mensual_regular:
            this.state.pricingAPI[this.state.slugBootcamp]["price"][
              country
            ]["price_view_base_regular"],
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  renderPrice(price, prueba = false) {
    return (
      <>
        <div className="row" style={{ placeContent: "center" }}>
          <div className="col-12">
            <p
              style={{
                fontSize: "45px",
                color: prueba ? "white" : "white",
                fontWeight: "bold",
                paddingTop: "9px",
                paddingBottom: "9px",
              }}
            >
              {price}
              <span
                style={{
                  display: "inline-block",
                  fontSize: window.type_device ? "20px" : "25px",
                  marginLeft: "5px",
                }}
              >
                {" "}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }

  
  onRenderOption(option, complete) {
    return (
      <li>
        <ReactCountryFlag
          countryCode={option.key}
          svg
          style={{
            width: "2em",
            height: "2em",
            padding: "1px",
          }}
          title="país"
        />{" "}
        {complete ? option.countryNumber : option.key}
      </li>
    );
  }



  GetLinkWS() {
    if (window.type_device) {
      return `https://api.whatsapp.com/send?phone=51936670597&text=Hola, me gustaría confirmar mi participación en el evento online gratuito. ¡Gracias!`;
    } else {
      return `https://web.whatsapp.com/send?phone=51936670597&text=Hola, me gustaría confirmar mi participación en el evento online gratuito. ¡Gracias!`;
    }
  }

  GetLinkPay() {
    let linkVipPeru = "https://mpago.la/1PxXuL5";
    let linkVipUSD = "https://buy.stripe.com/9AQ5l27jm7vIeoofZm";
    if (this.state.countryCode.toLocaleUpperCase() === "PE" ){
      return linkVipPeru
    } else {
      return linkVipUSD
    }
  }



  openModalEmail() {
    this.setState({ Phone: "" });
    this.setState({ isOpenTemario: !this.state.isOpenTemario });
  }

  openApp(){
    const link = this.GetLinkWS();
    window.open(link, '_blank').focus();
  }

  DownloadPdf(e) {
    e.preventDefault();

    if (this.state.Email === "") {
      toast.warning("El campo Email es requerido.");
      return;
    }

    localStorage.setItem("email", this.state.Email);

    toast.success(
      !this.state.langUS
        ? "Hola!, enviamos el programa por Email, gracias!"
        : "Hello! We sent the program by Email, thank you!"
    );

    this.openModalEmail();

    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: this.state.slugBootcamp,
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        Token: "c3b4b89c",
      })
      .then((res) => {
        window.gtag_report_conversion();
        // window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
        // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);
      });
  }


  onRenderOption ( option, complete ) {
    return (
        <li>
        <ReactCountryFlag
            countryCode={option.key}
            svg
            style={{
                width: '1.5em',
                height: '1.5em',
                padding: '1px'
            }}
            title="país"
        />  {option.key } {option.countryNumber }
        </li>
            )
}

onRenderSelection( selected, complete ){
    return 	(<span style={{ padding: 2 }}>
    { selected ?
        <span style={{"color": "white"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2em',
                height: '2em',
                padding: '2px'
            }}
            title="país"
    />
        </span>
        :
           <span style={{padding: 5}}></span>
    }
</span>)
}

renderCard2(index, modIndex) {
  const item = data2[modIndex];
  return (
      <div
      key={index}
      className="carousel-card"
      >
      <div style={{width: "275px"}}>
         <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "23px", "background": "white"}}>
              <h4>
              {item.title}
              </h4>
              <img alt={item.title} height="130px" src={item.background} />
          </div>
      </div>
      </div>
  );
  }


renderPais(complete=false){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > Zona horaria </span>
            <Selectrix
        height={300}
        onRenderOption={(option) => this.onRenderOption(option, complete)}
        onRenderSelection={(option) => this.onRenderSelection(option, complete)}
        placeHolderInside={true}
        placeholder={"País"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.countryCode}
        options={KeyCountry}
  
    />
        </>

    )
}




renderMes(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > {!this.state.langUS ? "Mes:": "Month:"} </span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={"Todos"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_mes}
        options={this.state.DataFechas.map((item) => ({key: item.mes, label: item.mes})) }
        onChange={value => this.setState({select_mes: value.key})}
    />
        </>

    )
}

renderTurno(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} >{!this.state.langUS ? "Turno": "Shift"}:</span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={!this.state.langUS ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_turno}
        options={this.state.Turnos}
        onChange={value => this.setState({select_turno: value.key})}
    />
        </>

    )
}

    renderHorario(){
    if (this.state.utc_offset === "-0500") {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:35 PM</p>
    } else if (this.state.utc_offset === "-0600") {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">6:00 PM a 8:35 PM</p>
    } else if (this.state.utc_offset === "-0400"){
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
    }  else {
        return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:35 PM</p>
    }
}
      renderHorarioFinSemana(){
        if (this.state.utc_offset === "-0500") {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 AM a 11:35 AM</p>
        } else if (this.state.utc_offset === "-0600"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 AM a 10:35 AM</p>
        } else if (this.state.utc_offset === "-0400"){
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 M</p>
        } else {
            return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 AM a 11:35 AM</p>
        }
    }

    renderHorarioFinSemanaTarde(){
        return (
        <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">05:00 PM a 7:35 PM</p>
        )
    }

renderFechaSelect(item, key){
    return (
        <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}} >
        <div className="col-12 mb-3">

            <h2 style={{marginBottom: "0px", fontSize: window.type_device && "39px" }}>
                {item.fecha_inicio}
            </h2>
            <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

            <div className="row">
                <div className="col-lg-2 col-md-3 col-12  mt-2">
                <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                <FiVideo/> {!this.state.langUS ? "En vivo Gratuito": "Live"}
                                </span>
                </div>
                <div className="col-md-4 col-lg-3 col-12  mt-2">
                    <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>
                        Plazas limitadas
                    </span>
                </div>
            </div>

        </div>

        <div className="col-md-3 col-12" style={{height: "110px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
            <div> <h5 className="mb-2" ><b> {!this.state.langUS ? "Duración": "Duration"} </b></h5> </div>
            <div> <p style={{fontSize: "18px"}}> {!this.state.langUS ? "7 meses": "5 months"}
            <span style={{display: "inline-block"}}></span>
            </p> </div>
            <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
            <b>{item.fecha_start_end}</b>
            </span>
        </div>

        <div className="col-md-3 col-12" style={{height: "110px", marginTop: window.type_device ? "25px": "none", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
            <div> <h5 className="mb-2"><b> {!this.state.langUS ? "Días": "Days"} </b></h5> </div>
            <div> <p style={{fontSize: "18px"}}> {item.dias}  </p> </div>
        </div>

        <div className="col-md-3 col-12" style={{height: "110px", marginTop: "none"}}>
            <div> <h5 className="mb-2"><b> {!this.state.langUS ? `Horario (${item.turno})`: `Schedule (${item.turno})`} </b></h5> </div>

            <div className="row">
                <div className="col-12">
                {(item.turno == 'noche' || item.turno == 'night') && this.renderHorario() }
                {item.turno == 'mañana'  && this.renderHorarioFinSemana() }
                {item.turno == 'tarde'  && this.renderHorarioFinSemanaTarde() }
                </div>

            </div>
        </div>

        <div className="col-md-3 col-12" style={{marginTop: "30px"}}>
            <div>
            <a href={"#inscripcion"}>
            <button
            style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
            className=" btn-efect">
            ELEGIR FECHA
            </button>
            </a>
            </div>
        </div>

    </div>
    )
  }

renderFechas() {
    return (
        <div>
            {!this.state.select_mes && this.state.DataFechas.map((item, key) => (

                this.state.select_turno ?
                 this.state.select_turno == item.turno && this.renderFechaSelect(item, key)
                :
                this.renderFechaSelect(item, key)

            ))}

            {this.state.select_mes && this.state.DataFechas.map((item, key) => (

                this.state.select_turno ?
                this.state.select_turno == item.turno && this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
               :
                this.state.select_mes == item.mes && this.renderFechaSelect(item, key)


            ))}
      </div>

    )}


    renderFormLead (titleBtn) {
      return (
        <>
        <div className="inner">
    <div className="contact-form--1">
            <div className="row justify-content-center" data-aos="fade-right" >

<div data-aos="fade-right" className="col-12 col-md-6 px-1 mt-1" style={{marginBottom: "20px"}}  id="registro">


<h2  className="title mb-2 mt-1 mx-3 text-white" style={{fontSize: window.type_device ? "35px": "45px" }} >
                ¡Sé Parte de la Revolución Digital!
                
                <div className=" mx-2" style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                </h2>

                <p className="text-white px-3">
                🎁 ¡Regalo Exclusivo! Al registrarte, podrás ganar una Beca Completa para nuestro Bootcamp Full Stack. ¡No dejes pasar esta oportunidad de transformar tu carrera profesional!
                </p>

                <span
                     style={{display: "inline-block", padding: "3px", marginLeft: "15px", "background-color": "tomato", "border-radius": "17px", "padding-left": "15px", "padding-right": "15px", "color": "white", "font-size": window.type_device ? "23px": "27px" }}>
                    ¡Evento Online en Vivo!
                    </span>

<div className="px-1" style={{paddingTop: "10px", paddingBottom: "10px" }}>

<label className="col-12 text-left font-weight-bold text-black" htmlFor="nameDojo">
                   
          <span style={{fontWeight: "normal", fontFamily: "'Poppins',sans-serif", padding: "5px", color: "white", fontSize: "20px", display: "inline-block", marginBottom: "1px"}}>
                        👋 ¡Hola! ¿Cuál es tu nombre?
          </span>
                            <input
                            id='nameDojo'
                            style={{marginBottom: "5px", backgroundColor: 'white'}}
                            type="text"
                            name="name"
                            value={this.state.Name}
                            onChange={(e) => this.setState({ Name: e.target.value})}
                            placeholder= {"Mi nombre"}
                            />
                        </label>

<label className="col-12 text-left font-weight-bold text-white mt-2" htmlFor="emailDojo">
          <span style={{fontWeight: "normal", fontFamily: "'Poppins',sans-serif", padding: "5px", color: "white", fontSize: "20px", display: "inline-block", marginBottom: "1px"}}>
          📧 Ingresa tu Correo Electrónico
          </span>
          <input
          style={{marginBottom: "5px", backgroundColor: 'white'}}
          type="email"
          name="email"
          id="emailDojo"
          value={this.state.Email}
          onChange={(e) => this.setState({ Email: e.target.value})}
          placeholder="tunombre@email.com"
          />
</label>

{/* 
<label className="col-12 text-left font-weight-bold text-white mt-2" htmlFor="">
<div className="text-left mt-1 pt-1" >
    <span style={{fontWeight: "normal", fontSize: "20px", color: "white", fontFamily: "'Poppins',sans-serif"}}>
    📱 Tu WhatsApp 
    </span>
    <span style={{fontSize: "18px"}}><br/> (Opcional, para recibir recordatorio)</span>
</div>
</label>


<div className="d-flex justify-content-center mx-2">

<div className="mx-2 mt-2">
    <Selectrix
    className="pais-inscription"
    height={300}
    onRenderOption={this.onRenderOption}
    onRenderSelection={this.onRenderSelection}
    placeHolderInside={true}
    placeholder={"País"}
    customScrollbar={true}
    searchable={false}
    materialize={false}
    defaultValue={this.state.countryCode}
    options={KeyCountry}
    onChange={(value) => {
        if (this.state.Email){
            localStorage.setItem('email', this.state.Email);
        }
        if (this.state.Name){
            localStorage.setItem('name', this.state.Name);
        }
        if (this.state.Phone){
            localStorage.setItem('phone', this.state.Phone);
        }
    } }
    />

</div>

<div style={{width: "80%"}}>
<label htmlFor="Phone">
    
    <input
    style={{background: "white"}}
    type="tel"
    name="phone"
    id="Phone"
    value={this.state.Phone}
    onChange={(e) => {
        this.setState({ Phone: e.target.value });
    }}
    placeholder="987654321"
    />
 </label>
</div>
</div> */}

<p className="my-0 py-0 mb-2" style={{textAlign: "center", color: "tomato", fontSize: "18px"}}>
          Quedan solo 27 plazas disponibles...
          </p>


<button
      onClick={this.onSubmit}
      disabled={this.state.loadingEnroll}
      id="submit-contacto"
      className="btn-efect-live boton-bombeo"
      type="btn" name="submit-contacto"  style={{backgroundColor: "tomato", color: "white", width: "100%", fontSize: "27px", display: "block", margin: "auto", textTransform: "capitalize"}}>

{this.state.loadingEnroll  &&
      <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      style={{fontSize: "20px", marginRight: "8px"}}
      />
}
<TiTicket/> {titleBtn}
</button>
    
</div>

<div className="row mt-4 text-center px-3">
          <div className="col-12 mx-0" style={{color: "white", fontSize: "18px", textAlign: "center", fontStyle: "italic"}}>
          <label>
              <span>
              Este evento es 100% online Gratuito.
              </span>
          </label>
       
          </div>
      </div>


</div>
</div>
</div>
</div>

        </>
      )
    }




    

  render() {
    const TypingAnimation2 =  React.memo(()=>{
      return !this.state.langUS ?
      <Typical
      steps={['Aprende 100% online en vivo', 500 ,'con la guía de tu Mentor.', 1000]}
      loop={Infinity}
      wrapper="h2"/>
      :
      <Typical
      steps={['Learn 100% online live', 500 ,'with the guidance of your Mentor.', 1000]}
      loop={Infinity}
      wrapper="h2"
  />
    },(props,prevProp)=> true ); // this line prevent re rendering
    

    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Bootcamp Web Full Stack" />
        {/* End Pagehelmet  */}

        {/* <canvas id="dojo-mural"></canvas> */}

        <div className="w-100 dojo-mural pb-2" >

        <div className="title-mural" style={{justifyItems: "center"}}>

        <div  style={{paddingLeft: "1px",  paddingTop: "10px", textAlign: "center", maxWidth: "590px", justifyItems: "center"}} >
    
    <div className="d-flex">
     <a href={'/registro'}>
        <img style={{borderRadius: "50%"}} height="40px" class="logo-1" src="/assets/images/logo/dojopy_software.webp" alt="DojoFullStack"/>
    </a>
    <div className="logo_dojopy" style={{color: "white", marginTop: "5px", marginBottom: "5px", fontFamily: "'El Messiri', sans-serif", lineHeight: "19px", "place-self": "center", "margin-left": "5px"}}>

<a href={'/registro'} style={{color: "white"}}>
<span style={{color: "white", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "25px": "30px" }}>
  DOJO
</span>
<span style={{color: "white", marginLeft: "1px", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "11px": "14px", display: "block"}}>
  FULLSTACK
</span>
</a>
</div>
    </div>
    


    <div>
    
  
  
   {false && this.state.fechaLimite !== "" &&
      <div id="counter-deadline" className="pt-1 pb-2">
     
          <h5 className="mt-2 mb-0" style={{fontSize: "17px", color: "black", textAlign: "center", fontWeight: "bold"}} >
              
          {/* Pago único de solo {this.state.price_view_base}, ¡Últimas horas para obtener el descuento! */}

          ⚡ ¡ÚLTIMAS HORAS! 50% OFF PAGO ÚNICO: {this.state.price_view_base} (antes {this.state.price_completo_regular}) ⚡

          </h5>
          <FlipCountdown
                              size={'extra-small'}
                              yearTitle='Year'
                              dayTitle='Días'
                              hourTitle='Horas'
                              minuteTitle='Min'
                              secondTitle='Segs'
                              monthTitle=''
                              hideYear
                              hideMonth
                              // hideDay
                              endAt={this.state.fechaLimite} // Date/Time
                              onTimeUp={() => {location.href = "/"} }
                              />
              <h5 className="my-0 py-0 pt-1 px-3" style={{fontWeight: "normal", fontSize: "14.5px", color: "white"}}>
              El descuento se desactiva automáticamente cuando el tiempo termine.
              </h5>
      </div>
      }

    </div>
 
      </div>

  {this.state.fechaLimite &&
  <>

    <div className="d-flex mt-1 justify-content-center">
    <div style={{marginLeft: "5px"}}>
    <FlipCountdown
          size={'small'}
          yearTitle='Year'
          dayTitle='Días'
          hourTitle='Horas'
          minuteTitle='Min'
          secondTitle='Segs'
          monthTitle='Mes'
          hideYear
          hideMonth
          titlePosition={"bottom"}
          endAt={this.state.fechaLimite} // Date/Time
          />
    </div>

    </div>
    <p className="pb-0 pt-2" style={{textAlign: "center", color: "white", fontStyle: "italic", fontSize: "17px"}}>
          🚨 Solo 30 Plazas Disponibles – ¡Regístrate antes de que se agoten!
          </p>

    </>

    }

{/* 
<h3 className="mt-3 mb-1 text-center" style={{color: "white", fontSize: "23px"}}>
Impulsa tu futuro
</h3> */}


          <h3 className="my-3 text-center" style={{color: "white"}}>
          De Cero a Full Stack Web en 7 meses y consigue tu primer empleo Tech
          <br/> <span style={{color: "tomato", fontFamily: "'Poppins',sans-serif"}}>
            ¡Sin Experiencia Previa!</span> 
          </h3>

     
          
          {/* <h5 className="pt-2 text-center" style={{fontSize: "18px", color: "white"}}>
          Aprende las tecnologías más demandadas (JavaScript, React, Python) y consigue tu primer trabajo en Tech 
          con nuestro método probado. ¡50% de PAGO UNICO DEes4uento por tiempo limitado!
          </h5> */}

        </div>

      </div>



<div>




<div className="about-wrapper" style={{paddingTop: window.type_device ? "5px": "50px", paddingBottom: "50px" }} id="webinar">




<div data-aos="fade-right"  className={window.type_device ? "container-fluid ": "container"}>
 
 <p className="text-white mt-3 text-center" style={{fontStyle: "italic", fontSize: "17px"}}>
  "Grandes visionarios como Elon Musk y Mark Zuckerberg saben que arriesgarse es clave para crecer. ¡Empieza tu camino hoy!"
 </p>


{true && 
<div className="about-wrapper" style={{paddingTop: window.type_device ? "5px": "19px", paddingBottom: "5px", color: "white"}}>
<div data-aos="fade-top"  className={window.type_device ? "container-fluid ": "container"}>

 <div className="d-flex mt-1 justify-content-center text-dark" style={{flexDirection: window.type_device ? "column": "row", gap: window.type_device ? "1px": "15px" }}>

 <div className="d-flex mb-3 col-md-4" style={{borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/1-face.webp" />
    <div className="ml-2 mt-2">
      <h5 style={{fontSize: "17px", color: "white", fontStyle: "italic"}} className="my-0">Elon Musk
        <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>CEO SpaceX & Twitter</span>
      </h5>
      <p style={{color: "white", fontSize: "13px", fontStyle: "italic"}}>La vida es demasiado corta para ser pequeña. Tienes que hacer algo grande.</p>
    </div>
  </div>

<div className="d-flex mb-3 col-md-4" style={{borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/3-face.jpg" />
    <div className="ml-2 mt-2">
      <h5 style={{fontSize: "17px", color: "white", fontStyle: "italic"}} className="my-0">Mark Zuckerberg
        <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>CEO Facebook</span>
      </h5>
      <p style={{color: "white", fontSize: "13px", fontStyle: "italic"}}>
      El miedo a lo desconocido nos limita; arriesgarse es clave para conocer nuestras capacidades.
        </p>
    </div>
  </div>


{/* 
  <div className="d-flex mb-1 col-md-4" style={{borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/2-face.jpeg" />
    <div className="ml-2 mt-2">
      <h5 style={{fontSize: "17px", color: "white", fontStyle: "italic"}} className="my-0">Simón Borrero
        <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>Founder Rappi</span>
      </h5>
      <p style={{color: "white", fontSize: "13px", fontStyle: "italic"}}>
      La tecnología puede cambiar el mundo, pero el verdadero cambio lo generan las personas.
        </p>
    </div>
  </div>  */}


</div>
</div>
</div>
    }



<h2 className="dojo-h2 mt-3" style={{color: "white", padding: window.type_device ? "5px 10px": "20px 70px"  }}>
Descubre un plan estructurado y comprobado para comenzar tu carrera como Desarrollador Web Full Stack desde cero, ¡sin necesidad de experiencia previa!
 Te guiaremos en cada paso del camino para que adquieras las habilidades necesarias y te sumerjas en el emocionante mundo del desarrollo web Full Stack.
  </h2>


   <div className="row" style={{justifyContent: "center", marginTop: window.type_device? "10px": "1px", padding: window.type_device ? "5px 10px": "20px 70px" }}>
                                                <div className="about-us-list" style={{color: "mediumpurple"}}>
                                                    <p style={{fontSize: "18px"}}>
                                                      <span style={{"color":"white", "fontSize": "19px"}}> <FiCheckCircle style={{fontSize: "23px", marginRight: "7px", color: "mediumpurple"}} /> </span>
                                                        No se requieren conocimientos previos.
                                                     <br/>
                                                 
                                                     <div className="pt-2">
                                                        <span style={{"color":"white", "fontSize": "19px"}}> <FiCheckCircle style={{fontSize: "23px", marginRight: "7px", color: "mediumpurple"}} /> </span>  
                                                          Aprendizaje paso a paso garantizado.
                                                        <br/>
                                                        </div>

                                                        <div className="pt-2">
                                                        <span style={{"color":"white", "fontSize": "19px"}}> <FiCheckCircle style={{fontSize: "23px", marginRight: "7px", color: "mediumpurple"}} /> </span>  
                                                          Sin costos mensuales.
                                                        <br/>
                                                        </div>

                                                        <div className="pt-2">
                                                        <span style={{"color":"white", "fontSize": "19px"}}> <FiCheckCircle style={{fontSize: "23px", marginRight: "7px", color: "mediumpurple"}} /> </span>  
                                                        Elige tu modalidad: En vivo o a tu ritmo.
                                                        <br/>
                                                        </div>

                                                        <div className="pt-2">
                                                        <span style={{"color":"white", "fontSize": "19px"}}> <FiCheckCircle style={{fontSize: "23px", marginRight: "7px", color: "mediumpurple"}} /> </span>  
                                                        Mejor empleabilidad y altos salarios
                                                        <br/>
                                                        </div>

                                                    </p>
                                                </div>
                                            </div>



  <div className="row mb-3 mt-3">
      <div className="col-6 col-md-4 text-center p-1">
      <FaRegCalendarAlt style={{color: "white", width: "50px", height: "80px"}}/>
      {/* <p className="mt-1 p-2 text-white" style={{fontSize: "18px"}} > <b>{this.state.dia}</b> <br/> (Único Día) </p> */}
      </div>


      <div className="col-6 col-md-4 text-center p-1">
      <IoMdTime style={{color: "white", width: "60px", height: "80px"}}/>
        <p className="mt-1 p-2" style={{fontSize: "18px"}} >
        
        
        <b style={{color: "white"}}> 07:00PM a 10:00PM <br/>
        <ReactCountryFlag
                countryCode={this.state.countryCode}
                svg
                style={{
                    width: '1.7em',
                    height: '1.7em',
                    padding: '1px'
                }}
                title="país"
            />
            <span className="ml-1">(2 horas 45m)</span>
        </b>

        </p>
      </div>

      <div className="col-12 col-md-4 text-center p-1">
      <img style={{height: window.type_device ? "65px": "80px"}} src="/assets/images/dojopy/live-full.png"/>
      <p className="mt-1 p-2" style={{fontSize: "19px", color: "white"}} >Transmisión en <br/> <b>Vivo</b></p>
      </div>

    

  </div>


{this.renderFormLead("¡QUIERO MI ENTRADA GRATIS!")}




                     
<div className="inner">
    <div className="contact-form--1">
            <div className="row" data-aos="fade-right" >
                <div className="col-12"
                style={{paddingLeft: window.type_device ? "5px": "50px", paddingRight: window.type_device ? "5px": "50px"}}
                >
         






    <p className="mt-4 mb-2 p-2 text-white" style={{fontSize: "30px", fontWeight: "bold", fontFamily: '"Poppins",sans-serif'}}>
    Testimonios
</p>



<div className=" mx-2" style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>




<p className="text-white mt-3 text-center" style={{fontStyle: "italic", fontSize: "17px"}}>
  📢 "Más del 93.7% de nuestros alumnos han conseguido su primer empleo como Full Stack en menos de 7 meses con nuestro método.
  <br/> ¡Tú puedes ser el siguiente!"
 </p>



<div className="d-flex my-3" style={{borderRadius: "15px", boxShadow: "rgb(222 226 230 / 99%) 3px 1px 5px 0px", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="79px"
    src="/assets/images/dojopy/019209102.png" 
    />
    <div className="ml-2 mt-2">
      <h5 style={{fontStyle: "italic", color: "white"}} className="my-0">Javier L.
      <FaFacebookSquare style={{marginLeft: "5px", fontSize: "20px", color: "#1877F2"}}/>

        <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}></span>
      </h5>
      <p className="my-0"><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/></p>

      <p style={{color: "white", fontSize: "13px", fontStyle: "italic"}}>
      "Como estudiante de informática, sentía que me faltaba experiencia práctica para destacar en el mercado laboral. Gracias al bootcamp, pude desarrollar proyectos reales y adquirir habilidades actuales que no enseñan en la universidad. Ahora, mientras termino mis estudios, ya trabajo como Full Stack Developer en una empresa de tecnología, ganando más de $4,000/mes. ¡El bootcamp fue el complemento perfecto para mi carrera!"

      <br/>
      <b>
      – Javier L., estudiante de informática y desarrollador en una empresa de tecnología.

      </b>
      </p>
    </div>
  </div>

  <div className="d-flex my-3" style={{borderRadius: "15px", boxShadow: "rgb(222 226 230 / 99%) 3px 1px 5px 0px", padding: "5px", alignItems: "center"}}>
  <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="79px"
    src="/assets/images/team/faces/face_3.webp" 
    />
    <div className="ml-2 mt-2">
      <h5 style={{fontStyle: "italic", color: "white"}} className="my-0">Bladimir G.
        <FaFacebookSquare style={{marginLeft: "5px", fontSize: "20px", color: "#1877F2"}}/>
        <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}></span>
      </h5>
      <p className="my-0"><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/></p>
      
      <p style={{color: "white", fontSize: "13px", fontStyle: "italic"}}>
      "Decidí inscribirme en el bootcamp Full Stack Web y fue la mejor decisión de mi vida. Ahora tengo la oportunidad de trabajar con importantes empresas y mis ingresos han mejorado significativamente ¡Gracias Dojofullstack, por brindarme una educación de calidad!"
      <br/>
      <b>
         – Bladimir G., ex principiante y ahora Developer en una empresa internacional.
      </b>
      </p>
    </div>
  </div>


  <div className="d-flex my-3" style={{borderRadius: "15px", boxShadow: "rgb(222 226 230 / 99%) 3px 1px 5px 0px", padding: "5px", alignItems: "center"}}>
  <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="79px"
    src="/assets/images/team/faces/face_4.webp" 
    />
    <div className="ml-2 mt-2">
      <h5 style={{fontStyle: "italic", color: "white"}} className="my-0">Victor R.
      <FaFacebookSquare style={{marginLeft: "5px", fontSize: "20px", color: "#1877F2"}}/>

        <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}></span>
      </h5>
      <p className="my-0"><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/></p>

      <p style={{color: "white", fontSize: "13px", fontStyle: "italic"}}>
      "Cuando seguí esta ruta, pasé de vender ropa en línea a ganar $3,000/mes como Developer Full Stack."
      <br/>
      <b>
         – Victor R., ex principiante y ahora Developer en una empresa internacional.
      </b>
      </p>
    </div>
  </div>





  <div className="d-flex my-3" style={{borderRadius: "15px", boxShadow: "rgb(222 226 230 / 99%) 3px 1px 5px 0px", padding: "5px", alignItems: "center"}}>
  <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="79px"
    src="/assets/images/team/faces/face_1.webp" 
    />
    <div className="ml-2 mt-2">
      <h5 style={{fontStyle: "italic", color: "white"}} className="my-0">Laura M.
      <FaFacebookSquare style={{marginLeft: "5px", fontSize: "20px", color: "#1877F2"}}/>

        <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}></span>
      </h5>
      <p className="my-0"><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/></p>

      <p style={{color: "white", fontSize: "13px", fontStyle: "italic"}}>
      "Soy mamá de dos niños y trabajaba como profesora de primaria.
A los 30 años, pensé que era tarde para cambiar de carrera…
¡Hoy trabajo como Full Stack Developer para una empresa alemana
y gano $3,800/mes desde casa! Lo mejor: veo crecer a mis hijos
mientras diseño aplicaciones educativas que usan miles de alumnos."

      <br/>
      <b>
         – Laura M., egresada del programa en 7 meses.

      </b>
      </p>
    </div>
  </div>



  <div className="d-flex my-3" style={{borderRadius: "15px", boxShadow: "rgb(222 226 230 / 99%) 3px 1px 5px 0px", padding: "5px", alignItems: "center"}}>
  <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="79px"
    src="/assets/images/dojopy/01920912.png" 
    />
    <div className="ml-2 mt-2">
      <h5 style={{fontStyle: "italic", color: "white"}} className="my-0">Maria Sanchez
      <FaFacebookSquare style={{marginLeft: "5px", fontSize: "20px", color: "#1877F2"}}/>

        <span style={{marginLeft: "5px", fontSize: "13px", color: "gray"}}></span>
      </h5>
      <p className="my-0"><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/><FaStar style={{color: "orange"}}/></p>

      <p style={{color: "white", fontSize: "13px", fontStyle: "italic"}}>
      Siempre pensé que una carrera en tecnología estaba fuera de mi alcance. Sin embargo, el bootcamp no solo me enseñó habilidades técnicas, sino también cómo aplicarlas en el mundo real. Ahora trabajo para una empresa de tecnología educativa y gano más de $2,300/mes. Lo mejor es que puedo trabajar remotamente y pasar tiempo de calidad con mi hijo.

      <br/>
      <b>
         – Maria Sanchez., egresada del programa en 7 meses.

      </b>
      </p>
    </div>
  </div>



  {this.renderFormLead("¡QUIERO APRENDER FULL STACK!")}


                </div>





                <div class="row my-3">
                    <div class="col-lg-12 col-12 mt-4" >
                        <div class="section-title service-style--3 mb--25 mb_sm--0 text-center">
                            <h3 class="title text-white px-4" style={{lineHeight: "2rem"}}>
                            Empresas Globales Quieren Tu Talento: Conviértete en Full Stack Developer en 7 Meses
                             </h3>

                             <div className="d-flex justify-content-center">
                              <img className="col-12 col-md-6 w-100 img-fluid" src="/assets/images/dojopy/brands_2025.png" alt="" srcset="" />
                             </div>
                        </div>
                    </div>
                    </div>



                    <p className="text-white my-2 text-center" style={{fontStyle: "italic", fontSize: "17px"}}>
                    "Sin compromiso, solo deja tu Email y únete GRATIS."
                    </p>




                </div>
        </div>
        </div>
        




    </div>
    <FooterTwoFunnel  />

  </div>



</div>



<Modal aria-labelledby="contained-modal-title-vcenter"
      centered show={this.state.isModalWS} size={window.type_device ? "md": "lg"} onHide={() => this.setState({isModalWS: true})} style={{zIndex: "9999999999"}} >

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                          <h3 className="mt-0 p-1 text-dark" style={{fontSize: "25px"}}>¡Gracias por registrarte! 🎉</h3>
                          <p className="p-2 text-dark">
                              Para confirmar tu participación y recibir el link del evento en vivo, por favor envíanos un 
                              <b> mensaje de confirmación </b> 
                               por WhatsApp. ¡Será genial contar contigo!
                            </p>

                            <div className="w-100 m-1">
                              <button onClick={() => {
                                 const link = this.GetLinkWS();
                                 window.open(link, '_blank').focus();
                              }}
                              className="btn-efect-live"
                              style={{background: "mediumspringgreen", borderRadius: "15px", color: "#1d1d24", width: "100%", fontSize: "23px", display: "block", margin: "auto", textTransform: "capitalize"}}>
                                <FaWhatsapp style={{fontSize: "25px", marginRight: "10px"}}/>
                                Confirmar por WhatsApp
                                </button>

                                <p>
                                  
                                </p>
                            </div>

                           
                    </div>
                    </div>
                    </Modal.Body>
</Modal>
                




    {/* Start About Area */}
       {false &&    <div data-aos="fade-right" className="about-area ptb--120 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                              ¿Para quién es esta Clase?
                                            </h2>

                                        </div>

                                        <TouchCarousel
                                        component={Container2}
                                        cardSize={cardSize2}
                                        cardCount={data2.length}
                                        cardPadCount={cardPadCount}
                                        loop={true}
                                        autoplay={2e3}
                                        renderCard={this.renderCard2}
                                        /> 


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
  }
                {/* End About Area */}


{/* <div className="container px-1" data-aos="fade-right" >
            <h2 className="title mb-2 mt-1 mx-2" style={{fontSize: window.type_device ? "36px": "37px"}} >
            Testimonios de Nuestros Alumnos
                </h2>
                    <span
                     style={{fontStyle: "italic", display: "block", padding: "3px", marginLeft: "7px", "border-radius": "7px", "color": "white", "font-size": window.type_device ? "19px": "30px" }}>
                          Más de 300 Alumnos Satisfechos
                    </span>

</div> */}
          




  {/* Start About Area */}
  <div className="about-area bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
{/*                             
                            <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Judith</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/alN6nU5a2Vo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>  
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Harold Ivan</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "100%": "560"} height="315" src="https://www.youtube.com/embed/CJWjmlO3_7k?si=0VWDgUmLhp4pyNRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                     */}

                              

                                {/* {!this.state.showMore &&
                                            <div className="col-12 text-center" style={{marginBottom: "80px"}}>
                                                <button
                                                onClick={() => this.setState({showMore: true}) }
                                                type="submit"
                                                name="submit"
                                                id="mc-embedded-subscribe-"
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "20px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {!this.state.langUS ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>} */}



                              




                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}




            

{true &&


<div >

      <div className="floating-button-container btn-efect-live" style={{borderRadius: 0, backgroundColor: "white"}}>

        <div className="floating-button" style={{padding: "1px" } }>

<a href="#registro">
<button
    className="btn-efect-2" style={{marginLeft: !window.type_device ? "13px": "", border: "1px solid white", backgroundColor: "tomato", padding: "10px 25px", fontSize: '21px', textTransform: "none", fontFamily: "Poppins, sans-serif"}}
    >
    ¡Reserva tu Plaza GRATIS Ahora!
</button>
</a>


       
            
            </div>

        </div>

        </div>


}







<ToastContainer
                position="bottom-center"
                autoClose={9000}
                zIndex={10000}
                />


      </React.Fragment>
    );
  }


  runtDiscountHot(codeDiscount){
    // console.log("module runDiscountFlash!");

    const getCookieValue = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const discount60mValue = getCookieValue(`discount-temporal-${codeDiscount}`);
    const discount24hValue = getCookieValue(`discount-reboot-${codeDiscount}`);

    if (discount60mValue){
        console.log("DESCUENTO ACTIVO!");
        const discount60mDate = new Date(parseInt(discount60mValue, 10));
        // console.log(discount60mDate);
        this.setState({fechaLimite: discount60mDate})
        return;
    }


    if ( !discount60mValue && !discount24hValue){
        console.log("CREANDO DESCUENTO!");

        const fechaActual = new Date();
        const fechaActual2 = new Date();

        const minutes = 60*12;
        const houresReboot = 24;

        fechaActual.setTime(fechaActual.getTime() +  (minutes * 60 * 1000) );
        fechaActual2.setTime(fechaActual2.getTime() +  (houresReboot * 60 * 60 * 1000) );

        document.cookie = `discount-temporal-${codeDiscount}=${fechaActual.getTime()};expires=${fechaActual.toUTCString() }; path=/`;
        document.cookie = `discount-reboot-${codeDiscount}=${fechaActual2.getTime()};expires=${fechaActual2.toUTCString() }; path=/`;
        this.setState({fechaLimite: fechaActual})
    } else if (!discount60mValue && discount24hValue){
        console.log("FINALIZO EL DESCUENTO!");
        this.setState({showDeadLine: false});
        window.location.href = `/`;
    }
   
}

  async componentDidMount() {
    // document.getElementById('whatsapp').style.display='block';
    document.body.style.backgroundColor = "rgb(27, 18, 18)";

    // window.urlWasi = this.GetLinkWS();

    this.sleep(10000).then(r => {
      this.setState({showWeb: true});
    })


    const respo = await axios.get(
      "https://edxcwnqqclvq7dccamuytezonu0hqrod.lambda-url.us-east-1.on.aws/"
    );
    const responseDemo = respo.data["evento"];
    this.setState({fechaLimite: responseDemo.fechaLimite, dia: responseDemo.dia});

    try {
      const responseIP = await axios.get("https://ipapi.co/json/");
      let dataPriceIP = responseIP.data;
      if (!dataPriceIP.country_code) throw "api error";
      this.setState({countryCode: dataPriceIP.country_code});

  } catch {
      axios.get('https://bdzpehnjfmdq56bcrp6gb4mcru0nxqci.lambda-url.us-east-1.on.aws/').then((response) => {
          let data = response.data;

          this.setState({countryCode: data.country_code});
          }).catch((error) => {
              console.log("error api");
          });
  }


      // this.showMatrix();



  }
}

export default FunnelStart;
