import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FcPodiumWithSpeaker } from "react-icons/fc";

const TestimonialOne = ({langUS=false, country=""}) => {


    const currentUrl = window.location.href;

    // Crear un objeto URL
    const urlObj = new URL(currentUrl);

    // Obtener el valor del parámetro 'code'
    const codeValue = urlObj.searchParams.get('code') || "";

    // Mostrar el valor
        
    return (
        <div className="row">

            <div className="col-12">

                <Tabs>
                   

                <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">

                                {country.toLowerCase() === "us" || codeValue.toLowerCase() === "us"  ?
                                 <>
                                 <p>
                                {langUS ?
                                <>
                                    Thanks to the Full Stack Web bootcamp, I secured a position at a prominent tech company. My income has grown to $8,367 per month, and I am excited about everything that lies ahead. This is just the beginning of my career!
                                </>: 
                                <>
                                   Gracias al bootcamp Full Stack Web, conseguí un puesto en una destacada empresa tecnológica.
                                   Mis ingresos han crecido hasta $8,367 dólares al mes y estoy entusiasmado por todo lo que está por venir
                                  ¡Este es solo el inicio de mi carrera!
                                </>
                                }
                             

                                     </p>
                                </>:
                                <>
                                 <p>
                                Gracias al bootcamp Full Stack Web, conseguí un puesto en una destacada empresa tecnológica.
                                Mis ingresos han crecido hasta $2,470 dólares al mes y estoy entusiasmado por todo lo que está por venir
                                 ¡Este es solo el inicio de mi carrera!
                                     </p>
                                </>
                                }
                               
                            </div>
                            <div className="author-info">
                            {!langUS ? <h6><span>Carlos</span> </h6>:  <h6><span>Michael</span> - from United States </h6>}
                               
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                                <p>
                                {langUS ?
                                <>
                                    The knowledge and best practices shared by the instructors through their experience were amazing, 100% recommended!
                                 </>: 
                                <>
                                    El conocimiento y buenas prácticas que dan a conocer los instructores a través de la experiencia, ¡me encantó 100% recomendado!
                                </>}
                                    </p>
                            </div>
                            <div className="author-info">
                                {!langUS ? <h6><span>Luciana</span>  </h6>:  <h6><span>Emily</span> - from United States </h6>}
                            </div>
                        </div>
                    </TabPanel>

                  
              
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">

                            {country.toLowerCase() === "us" || codeValue.toLowerCase() === "us"  ?
                                <>
                                  <p>

                                  {langUS ? <>
                                    After completing the Full Stack Web bootcamp, I earned $6,700 for the first time. The educational material was so practical that I was able to apply it immediately to my projects and see tangible results. It was a transformative experience!

                                  </>: 
                                  <>
                                                                  Después de completar el bootcamp de Full Stack Web,
                                 gané $6,700 dólares por primera vez. El material didáctico era tan práctico que pude aplicarlo de inmediato a mis proyectos y ver resultados tangibles ¡Fue una experiencia transformadora!

                                  </>
                                    }
                                    </p>
                                </>:
                                <>
                                 <p>
                                Después de completar el bootcamp de Full Stack Web, gané $1,600 dólares por primera vez. El material didáctico era tan práctico que pude aplicarlo de inmediato a mis proyectos y ver resultados tangibles ¡Fue una experiencia transformadora!
                                    </p>
                                </>
                                }

                           
                            </div>
                            <div className="author-info">
                                {!langUS ? <h6><span>Bladimir</span> </h6>:  <h6><span>Daniel</span> - Victor from EE.UU </h6>}
                            </div>
                        </div>
                    </TabPanel>

           
                    <TabPanel>
                        <div className="rn-testimonial-content text-center">
                            <div className="inner">
                            <p>
                            {langUS ?
                                <>
                                I decided to enroll in the Full Stack Web bootcamp, and it was the best decision of my life. Now I have the opportunity to work with major companies, and my income has significantly improved. Thank you, DojoFullStack, for providing me with a quality education!

                                </>:
                                <>
                                                            Decidí inscribirme en el bootcamp Full Stack Web y fue la mejor decisión de mi vida. Ahora tengo la oportunidad de trabajar con importantes empresas y mis ingresos han mejorado significativamente ¡Gracias Dojofullstack, por brindarme una educación de calidad!

                                </>
                            }

                                </p>
                            </div>
                            <div className="author-info">
                                {!langUS ? <h6><span>Victor</span> </h6>:  <h6><span>Christopher</span> - from Canada </h6>}
                            </div>
                        </div>
                    </TabPanel>
                    
                    <TabList className="testimonial-thumb-wrapper">
                    <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/team/faces/face_2.webp" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/team/faces/face_1.webp" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/team/faces/face_4.webp" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                        <Tab>
                            <div className="testimonial-thumbnai">
                                <div className="thumb">
                                    <img src="/assets/images/team/faces/face_3.webp" alt="Testimonial Images"/>
                                </div>
                            </div>
                        </Tab>
                      
                    
                    </TabList>

                </Tabs>



            </div>
        </div>
    )
}

export default TestimonialOne
