import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";


class PoliceReembolso extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false,
          username: window.location.pathname.split('/')[1],
        }
        this.openModal = this.openModal.bind(this);

    }

    openModal () {
        this.setState({isOpen: true})
    }

    render(){

        return(
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Políticas de Reembolso' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.webp" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h3 style={{color: 'white'}} className="title">
                

                {window.location.pathname.includes("refund-policies") ?
                "Refund Policies":
                "Políticas de Reembolso"    
                }
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}

                                        <div className="row sercice-details-content pb--80 align-items-center">
                                           
                                            <div className="col-12">
                                                <div className="details mt_md--30 mt_sm--30">

                                                {window.location.pathname.includes("refund-policies") ?

                                                <>
                                                <body class="c11"><h2 class="c8" id="h.laof9t6et3v3"><span class="c9">Refund Policy</span></h2><p class="c6"><span class="c0">Thank you for purchasing our services!</span></p><p class="c6"><span class="c0">
At DojoFullStack, we want to make sure that all of our members are satisfied with their learning experience.
If at any point you feel that this program is not right for you, we would like to hear your feedback and help you in any way possible.

If you have attended the live sessions and decide that this program is not what you need within the first 30 days from the start date, we offer a full refund.
This is executed after 30 business days of requesting it.
</span></p><p class="c6"><span class="c1">Eligibility for Refunds and Exchanges</span></p><ul class="c4 lst-kix_gf8tfl1o4ruu-0 start"><li class="c5 li-bullet-0"><span class="c0">To complete your return, we require a receipt or proof of purchase for payment.</span></li>
<li class="c5 li-bullet-0"><span class="c0">To complete your return, we require an attached document with the reasons or causes for the return explained.</span></li>
<li class="c5 li-bullet-0"><span class="c0">
We strive to process your refund request in a timely manner. <br/>
Our team will carefully evaluate the case within approximately 3-5 business days and make a decision based on the reasons specified. <br/> We are committed to finding a fair and satisfactory solution for all of our members.
</span></li>

</ul>

<p class="c7"><span class="c0"></span></p><p class="c6 c10"><span class="c0"></span></p><p class="c6"><span class="c1">Late or missing refunds</span></p><ul class="c4 lst-kix_h5bn97qliijk-0 start"><li class="c5 li-bullet-0"><span class="c0">If you haven't received a refund yet, first check your bank account again. Then, contact your credit card company. It may take some time before your refund is officially posted (on average 30 days).</span></li><li class="c5 li-bullet-0"><span class="c3">If you’ve done all of this and you still have not received your refund yet, please contact us at hello@dojofullstack.com</span></li></ul>

</body>
                                                </>:

                                                <>
                                                   <body class="c11"><h2 class="c8" id="h.laof9t6et3v3"><span class="c9">Política de Reembolso</span></h2><p class="c6"><span class="c0">¡Gracias por adquirir nuestros servicios!</span></p><p class="c6"><span class="c0">
                                                   En DojoFullStack, queremos asegurarnos de que todos nuestros miembros estén satisfechos con su experiencia de aprendizaje.
                                                   Si en algún momento sientes que este programa no es adecuado para ti, nos gustaría escuchar tus comentarios y ayudarte en lo que sea posible.

Si has asistido a las sesiones en vivo y decides que este programa no es lo que necesitas dentro de los primeros 30 días desde la fecha de inicio , te ofrecemos un reembolso completo.
                                                          este se ejecuta posterior a los 30 días hábiles de solicitarlo.
                                                           </span></p><p class="c6"><span class="c1">Elegibilidad para reembolsos y cambios</span></p><ul class="c4 lst-kix_gf8tfl1o4ruu-0 start"><li class="c5 li-bullet-0"><span class="c0">Para completar tu devolución, requerimos un recibo o comprobante de compra de pago.</span></li>
                                                           <li class="c5 li-bullet-0"><span class="c0">Para completar tu devolución requerimos un documento adjunto con las razones o causas de la devolución explicadas.</span></li>
                                                           <li class="c5 li-bullet-0"><span class="c0">
                                                           Nos esforzamos por procesar tu solicitud de reembolso de manera oportuna. <br/>
                                                           Nuestro equipo evaluará cuidadosamente el caso en un plazo aproximado de 3 a 5 días hábiles y tomará una decisión basada en las causas que se han especificado. <br/> Estamos comprometidos en encontrar una solución justa y satisfactoria para todos nuestros miembros.
                                                            </span></li>
                                                           
                                                            </ul>
                                                            
                                                            <p class="c7"><span class="c0"></span></p><p class="c6 c10"><span class="c0"></span></p><p class="c6"><span class="c1">Reembolsos atrasados o faltantes</span></p><ul class="c4 lst-kix_h5bn97qliijk-0 start"><li class="c5 li-bullet-0"><span class="c0">Si aún no has recibido un reembolso, primero verifica otra vez tu cuenta bancaria. Luego, comunícate con la compañía de tu tarjeta de crédito. Puede tomar algún tiempo antes de que se publique oficialmente tu reembolso (En promedio 30 días).</span></li><li class="c5 li-bullet-0"><span class="c3">Si has hecho todo esto y aún no has recibido tu reembolso, comunícate con nosotros al correo hola@dojofullstack.com</span></li></ul>
                                                            
                                                            </body>
                                                </>
                                            }
                                                
                                                <br/>
                                                </div>
                                            </div>

                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}

                {/* Start Back To Top */}
          
                {/* End Back To Top */}


                <FooterTwo />

            </React.Fragment>
        )
    }

    // async componentDidMount() {
    //    console.log(this.state.username)
    //   }
}
export default PoliceReembolso;
